import {
  Button,
  EButtonColorVariant,
  ELinkButtonVariant,
  EModalSize,
  ETextStyleVariant,
  LinkButton,
  Modal,
  Text,
} from '@outdoorsyco/bonfire';
import { FormattedMessage } from 'react-intl';

interface DeleteWishlistModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  wishlistName: string;
}

export const DeleteWishlistModal = ({
  isOpen,
  onClose,
  onDelete,
  wishlistName,
}: DeleteWishlistModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} size={EModalSize.Small}>
    <Modal.Actions />
    <Modal.Header>
      <FormattedMessage defaultMessage="Delete this wishlist?" id="t3SQek" />
    </Modal.Header>
    <Modal.Content className="pb-6">
      <Text variant={ETextStyleVariant.SmallRegular}>
        <FormattedMessage
          defaultMessage="{wishlistName} will be permanently deleted."
          id="DCUERB"
          values={{ wishlistName }}
        />
      </Text>
    </Modal.Content>
    <Modal.Divider />
    <Modal.Footer>
      <div className="flex flex-row items-center justify-between w-full">
        <LinkButton variant={ELinkButtonVariant.Underline} label="Cancel" onClick={onClose} />
        <Button
          variant={EButtonColorVariant.Primary}
          label="Delete"
          onClick={onDelete}
          aria-label={`Delete wishlist ${wishlistName}`}
        />
      </div>
    </Modal.Footer>
  </Modal>
);
