import { makeVar } from '@apollo/client';

const sessionStorageCache = makeVar<Record<string, string | null>>({});

export function getItemFromSessionStorage(key: string): string | null {
  if (canUseSessionStorage()) {
    try {
      const sessionStorageValue = window.sessionStorage.getItem(key);
      const cache = sessionStorageCache();
      if (cache[key] !== sessionStorageValue) {
        sessionStorageCache({ ...sessionStorageCache(), [key]: sessionStorageValue });
      }

      return sessionStorageValue;
    } catch {
      return sessionStorageCache()[key] || null;
    }
  }

  return sessionStorageCache()[key] || null;
}

export function setItemInSessionStorage(key: string, value: string): void {
  try {
    window.sessionStorage.setItem(key, value);
  } catch {
    return;
  } finally {
    sessionStorageCache({ ...sessionStorageCache(), [key]: value });
  }
}

function canUseSessionStorage(): boolean {
  try {
    if (!window?.sessionStorage) {
      return false;
    }
    window.sessionStorage.setItem('test', 'test');
    window.sessionStorage.removeItem('test');
    return true;
  } catch {
    return false;
  }
}
