export enum EWishlistRoles {
  Owner = 'owner',
  Collaborator = 'collaborator',
  None = 'none',
}

enum EWishlistPermissions {
  Read = 'read',
  EditRentals = 'edit_rentals',
  EditDetails = 'edit_details',
  EditUsers = 'edit_users',
  LeaveWishlist = 'leave_wishlist',
  Delete = 'delete',
}

const WishlistPermissionSets: Record<EWishlistPermissions, EWishlistRoles[]> = {
  [EWishlistPermissions.Read]: [EWishlistRoles.Owner, EWishlistRoles.Collaborator],
  [EWishlistPermissions.EditRentals]: [EWishlistRoles.Owner, EWishlistRoles.Collaborator],
  [EWishlistPermissions.EditDetails]: [EWishlistRoles.Owner, EWishlistRoles.Collaborator],
  [EWishlistPermissions.EditUsers]: [EWishlistRoles.Owner],
  [EWishlistPermissions.LeaveWishlist]: [EWishlistRoles.Collaborator],
  [EWishlistPermissions.Delete]: [EWishlistRoles.Owner],
};

export function canReadWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.Read].includes(role as EWishlistRoles)
  );
}

export function canEditRentalsInWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.EditRentals].includes(role as EWishlistRoles)
  );
}

export function canEditDetailsOfWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.EditDetails].includes(role as EWishlistRoles)
  );
}

export function canEditUsersInWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.EditUsers].includes(role as EWishlistRoles)
  );
}

export function canLeaveWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.LeaveWishlist].includes(role as EWishlistRoles)
  );
}

export function canDeleteWishlist(role?: string | null): boolean {
  return (
    role !== null &&
    WishlistPermissionSets[EWishlistPermissions.Delete].includes(role as EWishlistRoles)
  );
}
