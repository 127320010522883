import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { EToastVariant, useToast } from '@/hooks/useToast';
import {
  IWishlist,
  IWishlistCreateResponse,
  IWishlistResponse,
  useGetWishlists,
} from '@/hooks/useWishlists';
import { IFavorite } from '@/redux/modules/wishlists';
import { getAuthenticatedUser, getIsAuthenticated } from '@/redux/selectors/auth/user';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { getQuoteEventData } from '@/redux/selectors/listing/bill';
import {
  trackListingFavoritedEvent,
  trackListingUnfavoritedEvent,
} from '@/services/analytics/listings';
import { EListingSource, IWishlistingEventData } from '@/services/analytics/listings/types';
import { trackWishlistCreatedEvent } from '@/services/analytics/wishlists';
import apiRequest from '@/services/apiRequest';
import { trackEvent } from '@/services/track-event';
import { ICampsiteCategory } from '@/services/types/search/campgrounds/id';
import { IData } from '@/services/types/search/rentals/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { IRentalTile, ISearchResultTile } from '@/utility/mapSearchResultToTile';
import { getItemFromSessionStorage, setItemInSessionStorage } from '@/utility/sessionStorage';
import { triggerLoginModal } from '@/utility/triggerLoginModal';

import { WishlistModal } from './WishlistModal';
import { WishlistToast } from './WishlistToast';

type TTriggerComponentProps = {
  isFavorite: boolean | undefined;
  toggleFavorite: () => void;
};

type TWishlistModalWrapperProps = {
  isFavorite: boolean | undefined;
  rental: ISearchResultTile | ICampsiteCategory | IData;
  wishlistingEventData?: IWishlistingEventData;
  TriggerComponent: (props: TTriggerComponentProps) => React.ReactNode;
  isListingPage?: boolean;
};

export const WishlistModalWrapper = ({
  isFavorite,
  rental,
  wishlistingEventData,
  TriggerComponent,
  isListingPage,
}: TWishlistModalWrapperProps) => {
  const intl = useIntl();
  const { showToast, showCustomToast, closeToast } = useToast();
  const userFavorites = useSelector(getUserFavorites);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userData = useSelector(getAuthenticatedUser);
  const quoteEventData = useSelector(getQuoteEventData);
  const isOutdoorsyStay = quoteEventData?.isOutdoorsyStay;

  const { data: wishlists, mutate: refetchWishlists } = useGetWishlists({
    isAuthenticated,
    examplesOnly: false,
    excludeExamples: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const rentalId = (rental as ICampsiteCategory).rental_id || rental.id;

  const [isLoading, setIsLoading] = useState(false);
  const [toastFavorite, setToastFavorite] = useState<IFavorite | null>(null);

  const showGenericErrorToast = () => {
    showToast(
      EToastVariant.Error,
      intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
      intl.formatMessage({
        defaultMessage: 'Something went wrong, please try again.',
        id: '8GvVWZ',
      }),
    );
  };

  const handleClose = () => {
    setIsLoading(false);
    setIsOpen(false);
    setToastFavorite(null);
  };

  const handleShowCustomToast = (
    res: IWishlistResponse,
    wishlistId: number,
    rId: number,
    wishlistItemCount: number,
    creatorId?: number,
  ) => {
    showCustomToast(
      <WishlistToast
        selectedWishlistId={wishlistId}
        coverImageUrl={res.cover_image_url}
        wishlistTitle={res.title}
        rentalId={rId}
        wishlistItemCount={wishlistItemCount}
        creatorId={creatorId}
        onChangeWishlist={handleChangeStarted}
      />,
    );
  };

  const handleRemoveItemFromWishlist = async () => {
    const wishlistedRentalData = userFavorites.data.find(r => r.rental_id === rentalId);

    if (!isAuthenticated || !wishlistedRentalData) {
      return null;
    }

    const currentWishlistToRemoveFrom = wishlists?.find(w => w.id === wishlistedRentalData.id);

    const requestUrl = `${getCoreApi()}/wishlists/${wishlistedRentalData.id}/remove`;
    try {
      await apiRequest(
        {
          url: requestUrl,
          method: 'POST',
          data: {
            rental_id: rentalId,
          },
        },
        true,
      );

      if (
        wishlistingEventData?.eventSource &&
        wishlistingEventData.index !== undefined &&
        currentWishlistToRemoveFrom
      ) {
        const wishlistItemCount = currentWishlistToRemoveFrom.rental_count
          ? currentWishlistToRemoveFrom.rental_count - 1
          : 0;
        const listingFavoriteCount = (rental as ISearchResultTile).favoriteCount || 1;
        trackListingUnfavoritedEvent(
          rental as IRentalTile,
          wishlistingEventData.index + 1,
          wishlistingEventData.eventSource,
          isOutdoorsyStay,
          wishlistingEventData?.page,
          undefined,
          currentWishlistToRemoveFrom.id,
          currentWishlistToRemoveFrom.title,
          wishlistItemCount,
          listingFavoriteCount - 1,
          wishlistedRentalData.wishlist_creator_id,
        );
      }

      await refetchWishlists();

      handleClose();
    } catch {
      showGenericErrorToast();
      setSelectedWishlist(null);
      handleClose();
    }
  };

  const handleCreate = async (wishlistName: string) => {
    if (!rentalId) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await apiRequest<IWishlistCreateResponse>(
        {
          url: `${getCoreApi()}/wishlists`,
          method: 'POST',
          data: {
            title: wishlistName,
            rental_id: rentalId,
          },
        },
        true,
      );

      const newWishlistItemCount = 1;
      handleShowCustomToast(response, response.id, rentalId, newWishlistItemCount, userData?.id);

      trackWishlistCreatedEvent({
        rentalID: rentalId,
        wishlistName: wishlistName,
        wishlistID: response.id,
      });

      const newWishlistId = response.id;
      const res = await refetchWishlists();

      // BE returns only id in the initial res when creating a new wishlist
      // we will need to find the new wishlist after the refetch
      const newWishlist = res?.find(w => w.id === newWishlistId);
      if (newWishlist) {
        setSelectedWishlist(newWishlist);
      }

      if (wishlistingEventData?.eventSource && wishlistingEventData.index !== undefined) {
        const listingFavoriteCount = (rental as ISearchResultTile).favoriteCount || 0;
        trackListingFavoritedEvent(
          rental as IRentalTile,
          wishlistingEventData.index + 1,
          wishlistingEventData.eventSource,
          isOutdoorsyStay,
          wishlistingEventData?.page,
          undefined,
          response.id,
          wishlistName,
          newWishlist?.rental_count,
          listingFavoriteCount + 1,
          userData?.id,
        );
      }
    } catch {
      showGenericErrorToast();
    } finally {
      handleClose();
    }
  };

  const handleAddToWishlist = async (clickedWishlist: IWishlist | null) => {
    const selectedWishlist = getSelectedWishlist();
    if ((!clickedWishlist && !selectedWishlist) || !rentalId || isFavorite) {
      // prevent adding to wishlist if the rental is already in the user's favorites
      // or no wishlist is selected
      return;
    }

    setIsLoading(true);

    if (clickedWishlist) {
      setSelectedWishlist(clickedWishlist);
    }

    const wishlistId = clickedWishlist?.id || selectedWishlist?.id;
    if (!wishlistId) {
      return;
    }

    try {
      const res = await apiRequest<IWishlistResponse>(
        {
          url: `${getCoreApi()}/wishlists/${wishlistId}/add`,
          method: 'PUT',
          data: {
            rental_id: rentalId,
          },
        },
        true,
      );

      const wishlist = selectedWishlist || clickedWishlist;
      const creatorId = wishlist?.user_details.find(u => u.user_role === 'owner')?.id;
      const newWishlistItemCount = wishlist?.rental_count ? wishlist.rental_count + 1 : 1;
      if (wishlist) {
        handleShowCustomToast(res, wishlist.id, rentalId, newWishlistItemCount, creatorId);
      }

      if (wishlistingEventData?.eventSource && wishlistingEventData.index !== undefined) {
        const listingFavoriteCount = (rental as ISearchResultTile).favoriteCount || 0;
        trackListingFavoritedEvent(
          rental as IRentalTile,
          wishlistingEventData.index + 1,
          wishlistingEventData.eventSource,
          isOutdoorsyStay,
          wishlistingEventData?.page,
          undefined,
          wishlistId,
          wishlist?.title,
          newWishlistItemCount,
          listingFavoriteCount + 1,
          creatorId,
        );
      }

      await refetchWishlists();
    } catch {
      showGenericErrorToast();
      setSelectedWishlist(null);
    } finally {
      handleClose();
    }
  };

  const handleChangeWishlist = async (clickedWishlist: IWishlist | null) => {
    if (!clickedWishlist || !selectedWishlist || !toastFavorite) {
      return;
    }

    setIsLoading(true);

    // The BE call will remove the rental from the current wishlist and add it to the newly selected one
    // afterwards set selectedWishlist to the clickedWishlist
    const oldWishlistId = selectedWishlist.id;
    const newWishlistId = clickedWishlist.id;

    if (oldWishlistId === newWishlistId) {
      showToast(
        EToastVariant.Alert,
        intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        intl.formatMessage({
          defaultMessage: 'The rental is already included in the selected wishlist.',
          id: 'LWTiz3',
        }),
      );
      handleClose();
      return;
    }

    try {
      const response = await apiRequest<IWishlistResponse>(
        {
          url: `${getCoreApi()}/wishlists/change-wishlist`,
          method: 'POST',
          data: {
            old_wishlist_id: oldWishlistId,
            new_wishlist_id: newWishlistId,
            rental_id: toastFavorite.rental_id,
          },
        },
        true,
      );

      const creatorId = clickedWishlist.user_details.find(u => u.user_role === 'owner')?.id;
      const newWishlistItemCount = clickedWishlist.rental_count + 1;
      handleShowCustomToast(
        response,
        newWishlistId,
        toastFavorite.rental_id,
        newWishlistItemCount,
        creatorId,
      );
      setSelectedWishlist(clickedWishlist);

      trackListingFavoritedEvent(
        rental as IRentalTile,
        1,
        EListingSource.CHANGE_WISHLIST,
        isOutdoorsyStay,
        undefined,
        undefined,
        clickedWishlist.id,
        clickedWishlist.title,
        newWishlistItemCount,
        (rental as ISearchResultTile).favoriteCount,
        creatorId,
      );

      await refetchWishlists();
    } catch {
      showGenericErrorToast();
      setSelectedWishlist(null);
    } finally {
      handleClose();
    }
  };

  const toggleFavorite = () => {
    if (!isAuthenticated) {
      triggerLoginModal(0);
      return null;
    }

    if (isFavorite) {
      handleRemoveItemFromWishlist();
    } else {
      if (selectedWishlist) {
        handleAddToWishlist(null);
      } else {
        setIsOpen(true);
      }
    }

    if (isListingPage) {
      trackEvent({
        event: 'Listing : Save',
        action: 'save',
        rental_id: rentalId,
      });
    } else {
      trackEvent({ event: 'rv-show/click', action: 'save', rentalId: rentalId });
    }
  };

  const getSelectedWishlist = () => {
    const storedWishlistId = getItemFromSessionStorage('selectedWishlist');
    if (!storedWishlistId || !wishlists) return null;
    return wishlists.find(w => w.id.toString() === storedWishlistId);
  };

  const setSelectedWishlist = (wishlist: IWishlist | null) => {
    setItemInSessionStorage('selectedWishlist', wishlist?.id?.toString() || '');
  };

  const handleChangeStarted = (toastFavorites: IFavorite[]) => {
    const foundFavorite = toastFavorites.find(f => f.rental_id === rentalId);
    // close all active toasts to prevent multiple modal instances from being open
    closeToast();

    if (foundFavorite) {
      //remember the favorite to be possibly moved into a new wishlist
      // in case no favorite is found, only open the modal to wishlist the rental again
      // could happen if the user first removes from wishlist with the heart button and then clicks the toast
      setToastFavorite(foundFavorite);
    }

    setIsLoading(false);
    setIsOpen(true);
  };

  const selectedWishlist = getSelectedWishlist();

  return (
    <>
      <TriggerComponent isFavorite={isFavorite} toggleFavorite={toggleFavorite} />
      {isOpen && (
        // Render the modal inside a hidden div
        // and use it to prevent clicks from bubbling up to the tile
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div className="hidden" onClick={e => e.stopPropagation()}>
          <WishlistModal
            isOpen={isOpen}
            rental={rental}
            wishlists={wishlists}
            isLoading={isLoading}
            selectedWishlist={selectedWishlist}
            onClose={handleClose}
            onCreateWishlist={(wishlistName: string) => handleCreate(wishlistName)}
            onAddToWishlist={toastFavorite ? handleChangeWishlist : handleAddToWishlist}
          />
        </div>
      )}
    </>
  );
};
