import { EShareMethodType } from '@/services/types/referral/referral';

export enum EWishlistEventName {
  WISHLIST_CREATED = 'Wishlist Created',
  WISHLIST_SELECTED = 'Wishlist Selected',
  WISHLIST_DELETED = 'Wishlist Deleted',
  WISHLIST_EDITED = 'Wishlist Edited',
  WISHLIST_SHARED = 'Wishlist Shared',
}

export enum EWishlistSelectedSource {
  WISHLIST_MAIN = 'wishlist_main',
  WISHLIST_TOAST = 'toast',
}

export interface IWishlistCreatedEvent {
  rentalID: number;
  wishlistName: string;
  wishlistID: number;
}

export interface IWishlistSelectedEvent {
  wishlistName: string;
  wishlistID: number | null; // null for recently viewed
  coverPhotoRentalID: number | null; // id of the rental whose cover photo is currently used for the wishlist
  wishlistItemCount: number;
  wishlistCreatorID: number | null;
  source: EWishlistSelectedSource;
}

export interface IWishlistDeletedEvent {
  wishlistName: string;
  wishlistID: number;
  coverPhotoRentalID: number | null;
  wishlistCreatorID: number | null;
}

export interface IWishlistEditedEvent {
  wishlistID: number;
  coverPhotoRentalID: number | null;
  wishlistCreatorID: number | null;
  wishlistName: string | null;
  departureDate: number | null;
  returnDate: number | null;
  totalAdults: number | null;
  totalChildren: number | null;
}

export interface IWishlistSharedEvent {
  wishlistName: string;
  wishlistID: number;
  coverPhotoRentalID: number | null;
  wishlistItemList: number[];
  departureDate: number | null;
  returnDate: number | null;
  totalAdults: number | null;
  totalChildren: number | null;
  shareMethod: EShareMethodType;
}
