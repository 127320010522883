import {
  EIconButtonSize,
  EIconButtonVariant,
  ETextStyleVariant,
  EToastVariant,
  GeneralCloseIcon,
  GeneralHeartUnfilledIcon,
  IconButton,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import { clsx } from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useToast } from '@/hooks/useToast';
import { IWishlist } from '@/hooks/useWishlists';
import {
  trackWishlistDeletedEvent,
  trackWishlistSelectedEvent,
} from '@/services/analytics/wishlists';
import { EWishlistSelectedSource } from '@/services/analytics/wishlists/types';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { canDeleteWishlist, EWishlistRoles } from '@/utility/wishlists';

import { DeleteWishlistModal } from './DeleteWishlistModal';

type TWishlistCardProps = {
  wishlist: IWishlist;
  editMode?: boolean;
  nonDeletable?: boolean;
  refetchWishlists?: () => void;
  hasLinkWrapper?: boolean;
  hasOutline?: boolean;
};

export const WishlistCard = ({
  wishlist,
  editMode,
  nonDeletable,
  refetchWishlists,
  hasLinkWrapper = true,
  hasOutline = false,
}: TWishlistCardProps) => {
  const { id, title, rental_count, cover_image_url, user_role, rentals, user_details } = wishlist;

  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const { showToast } = useToast();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageError, setImageError] = useState(false);

  const showDeleteButton =
    (editMode || !isMobile) && !nonDeletable && canDeleteWishlist(user_role as EWishlistRoles);

  const handleDelete = useCallback(async () => {
    try {
      if (id) {
        await apiRequest(
          {
            url: `${getCoreApi()}/wishlists/${id}`,
            method: 'DELETE',
          },
          true,
        );
      }
      refetchWishlists?.();
      setShowDeleteModal(false);

      trackWishlistDeletedEvent({
        wishlistID: id,
        wishlistName: title,
        coverPhotoRentalID: rentals[0] || null,
        wishlistCreatorID: user_details.find(u => u.user_role === 'owner')?.id || null,
      });
    } catch {
      showToast(
        EToastVariant.Error,
        intl.formatMessage({ defaultMessage: 'Error deleting wishlist', id: 'jo6LV6' }),
        intl.formatMessage({
          defaultMessage: 'Please try again',
          id: 'fHqssj',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, intl, showToast, refetchWishlists]);

  const renderCoverContent = () => {
    if (!cover_image_url || imageError) {
      return (
        <div
          className={cn(
            'relative flex items-center justify-center aspect-square rounded-2xl bg-[#F1F2F3] group hover:outline outline-2 outline-offset-2 outline-green-500',
            {
              'outline outline-2 outline-offset-2 outline-green-500': hasOutline,
            },
          )}>
          <GeneralHeartUnfilledIcon fontSize={48} className="text-gray-310" />
          {showDeleteButton && (
            <div
              className={clsx(
                'absolute bg-white rounded-full cursor-pointer top-3 left-3',
                !isMobile && 'group-hover:opacity-100 opacity-0 transition-opacity duration-300',
              )}>
              <IconButton
                icon={GeneralCloseIcon}
                variant={EIconButtonVariant.Ghost}
                size={EIconButtonSize.Small}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
                aria-label={`Delete wishlist ${title}`}
                href="#"
              />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="relative aspect-square group">
        <Image
          src={cover_image_url}
          alt={`Wishlist cover for ${title}`}
          width={297}
          height={297}
          className={cn(
            'object-cover w-full h-full rounded-lg md:rounded-2xl aspect-square group-hover:outline outline-2 outline-offset-2 outline-green-500',
            {
              'outline outline-2 outline-offset-2 outline-green-500': hasOutline,
            },
          )}
          sizes="(max-width: 768px) 100vw, 297px"
          onError={() => setImageError(true)}
        />
        {showDeleteButton && (
          <div
            className={clsx(
              'absolute bg-white rounded-full cursor-pointer top-3 left-3',
              !isMobile && 'group-hover:opacity-100 opacity-0 transition-opacity duration-300',
            )}>
            <IconButton
              icon={GeneralCloseIcon}
              variant={EIconButtonVariant.Ghost}
              size={EIconButtonSize.Small}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setShowDeleteModal(true);
              }}
              aria-label={`Delete wishlist ${title}`}
              href="#"
            />
          </div>
        )}
      </div>
    );
  };

  const content = (
    <div className="flex flex-col w-full gap-2">
      {renderCoverContent()}
      <div className="flex flex-col">
        <Text variant={ETextStyleVariant.MediumBold} className="truncate">
          {title}
        </Text>
        <Text variant={ETextStyleVariant.SmallRegular} className="truncate">
          {rental_count} {rental_count === 1 ? 'item' : 'items'}
        </Text>
      </div>
    </div>
  );

  const handleClickWishlistCard = () => {
    trackWishlistSelectedEvent({
      wishlistID: id,
      wishlistName: title,
      coverPhotoRentalID: rentals[0] || null,
      wishlistItemCount: rental_count,
      wishlistCreatorID: user_details.find(u => u.user_role === 'owner')?.id || null,
      source: EWishlistSelectedSource.WISHLIST_MAIN,
    });
  };

  return (
    <>
      {hasLinkWrapper ? (
        <Link href={`/wishlists/${id}`} className="block w-full" onClick={handleClickWishlistCard}>
          {content}
        </Link>
      ) : (
        content
      )}

      <DeleteWishlistModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        wishlistName={title}
      />
    </>
  );
};
