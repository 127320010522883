import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalSize,
  EModalVariants,
  Heading,
  LinkButton,
  Modal,
  TextInput,
} from '@outdoorsyco/bonfire';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { WishlistCard } from '@/components/wishlists/WishlistCard';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IWishlist } from '@/hooks/useWishlists';
import { ICampsiteCategory } from '@/services/types/search/campgrounds/id';
import { IData } from '@/services/types/search/rentals/id';
import { ISearchResultTile } from '@/utility/mapSearchResultToTile';
import { canEditRentalsInWishlist } from '@/utility/wishlists';

import css from './WishlistModal.module.css';

type WishlistModalProps = {
  isOpen: boolean;
  rental: ISearchResultTile | ICampsiteCategory | IData;
  wishlists: IWishlist[] | undefined;
  isLoading: boolean;
  selectedWishlist?: IWishlist | null;
  onCreateWishlist: (wishlistName: string) => void;
  onAddToWishlist: (wishlist: IWishlist | null) => void;
  onClose: () => void;
};

const getInitialWishlistName = (rental: ISearchResultTile | ICampsiteCategory | IData) => {
  const fullRentalData = rental as IData;
  if (fullRentalData.location) {
    return `${fullRentalData.location.city} ${new Date().getFullYear()}`;
  }

  const campsiteRental = rental as ICampsiteCategory;
  if (campsiteRental.primary_image_url) {
    return campsiteRental.name;
  }

  const searchResultTile = rental as ISearchResultTile;
  if (searchResultTile) {
    if (searchResultTile.campground) {
      return searchResultTile.details?.location || '';
    } else {
      const { city } = searchResultTile.details || {};
      if (city) {
        return `${city} ${new Date().getFullYear()}`;
      } else {
        return '';
      }
    }
  }

  return '';
};

export const WishlistModal = ({
  isOpen,
  rental,
  wishlists,
  isLoading,
  selectedWishlist,
  onClose,
  onCreateWishlist,
  onAddToWishlist,
}: WishlistModalProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const [wishlistName, setWishlistName] = useState(() => {
    return getInitialWishlistName(rental);
  });
  const [isCreatingWishlist, setIsCreatingWishlist] = useState(!wishlists?.length);
  const inputRef = useRef<HTMLInputElement>(null);

  const wishlistHeading = isCreatingWishlist
    ? intl.formatMessage({ defaultMessage: 'Create wishlist', id: 'MFdAII' })
    : intl.formatMessage({ defaultMessage: 'Choose wishlist', id: 'Aylqsb' });

  const handleClear = () => {
    setWishlistName('');
  };

  const handleSwitchToCreating = () => {
    setWishlistName(getInitialWishlistName(rental));
    setIsCreatingWishlist(true);
  };

  useEffect(() => {
    if (isCreatingWishlist && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreatingWishlist]);

  return (
    <Modal
      className={css.slidingModal}
      isOpen={isOpen}
      size={EModalSize.Medium}
      variant={isMobile ? EModalVariants.Bottom : undefined}
      onClose={() => {
        onClose();
      }}>
      <Modal.Actions />

      <Modal.Header>
        <Heading variant={EHeadingStyleVariant.H5} className="text-center">
          {wishlistHeading}
        </Heading>
      </Modal.Header>

      <Modal.Content>
        <div className="py-4">
          {isCreatingWishlist ? (
            <TextInput
              controlled
              ref={inputRef}
              inputValue={wishlistName}
              ariaLabel={intl.formatMessage({ defaultMessage: 'Wishlist name', id: 'bghJvz' })}
              label={intl.formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' })}
              helpText={intl.formatMessage(
                { defaultMessage: '{count}/50 characters', id: 'l8/qu2' },
                { count: wishlistName.length },
              )}
              error={wishlistName.length > 50}
              onChange={e => setWishlistName(e)}
            />
          ) : (
            <div className="flex flex-wrap gap-4">
              {wishlists
                ?.filter(wishlist => canEditRentalsInWishlist(wishlist.user_role))
                .map((wishlist: IWishlist) => (
                  <button
                    key={wishlist.id}
                    className="text-left w-[calc(50%-8px)]"
                    disabled={isLoading}
                    onClick={() => onAddToWishlist(wishlist)}>
                    <WishlistCard
                      wishlist={wishlist}
                      editMode={false}
                      nonDeletable={true}
                      hasLinkWrapper={false}
                      hasOutline={selectedWishlist?.id === wishlist.id}
                    />
                  </button>
                ))}
            </div>
          )}
        </div>
      </Modal.Content>

      <Modal.Footer className="shadow-600 z-[1]" variant={EModalFooterVariants.Actions}>
        {isCreatingWishlist ? (
          <>
            <LinkButton
              label={intl.formatMessage({ defaultMessage: 'Clear', id: '/GCoTA' })}
              disabled={isLoading}
              onClick={handleClear}
            />

            <Button
              label={intl.formatMessage({ defaultMessage: 'Create', id: 'VzzYJk' })}
              variant={EButtonColorVariant.Primary}
              disabled={isLoading || wishlistName.length > 50}
              onClick={() => onCreateWishlist(wishlistName)}
            />
          </>
        ) : (
          <Button
            label={intl.formatMessage({ defaultMessage: 'Create new wishlist', id: 'GXJC43' })}
            variant={EButtonColorVariant.Primary}
            fullWidth
            disabled={isLoading}
            onClick={handleSwitchToCreating}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
