import { EProjectSection, trackEventV2 } from '../trackEventV2';
import {
  EWishlistEventName,
  IWishlistCreatedEvent,
  IWishlistDeletedEvent,
  IWishlistEditedEvent,
  IWishlistSelectedEvent,
  IWishlistSharedEvent,
} from './types';

export const trackWishlistCreatedEvent = (event: IWishlistCreatedEvent) => {
  trackEventV2(EWishlistEventName.WISHLIST_CREATED, EProjectSection.MARKETPLACE, {
    rentalID: event.rentalID,
    wishlistName: event.wishlistName,
    wishlistID: event.wishlistID,
  });
};

export const trackWishlistSelectedEvent = (event: IWishlistSelectedEvent) => {
  trackEventV2(EWishlistEventName.WISHLIST_SELECTED, EProjectSection.MARKETPLACE, {
    wishlistID: event.wishlistID,
    wishlistName: event.wishlistName,
    coverPhotoRentalID: event.coverPhotoRentalID,
    wishlistItemCount: event.wishlistItemCount,
    wishlistCreatorID: event.wishlistCreatorID,
    source: event.source,
  });
};

export const trackWishlistDeletedEvent = (event: IWishlistDeletedEvent) => {
  trackEventV2(EWishlistEventName.WISHLIST_DELETED, EProjectSection.MARKETPLACE, {
    wishlistID: event.wishlistID,
    wishlistName: event.wishlistName,
    coverPhotoRentalID: event.coverPhotoRentalID,
    wishlistCreatorID: event.wishlistCreatorID,
  });
};

export const trackWishlistEditedEvent = (event: IWishlistEditedEvent) => {
  trackEventV2(EWishlistEventName.WISHLIST_EDITED, EProjectSection.MARKETPLACE, {
    wishlistID: event.wishlistID,
    coverPhotoRentalID: event.coverPhotoRentalID,
    wishlistCreatorID: event.wishlistCreatorID,
    wishlistName: event.wishlistName,
    departureDate: event.departureDate,
    returnDate: event.returnDate,
    totalAdults: event.totalAdults,
    totalChildren: event.totalChildren,
  });
};

export const trackWishlistSharedEvent = (event: IWishlistSharedEvent) => {
  trackEventV2(EWishlistEventName.WISHLIST_SHARED, EProjectSection.MARKETPLACE, {
    wishlistID: event.wishlistID,
    wishlistName: event.wishlistName,
    coverPhotoRentalID: event.coverPhotoRentalID,
    wishlistItemList: event.wishlistItemList,
    departureDate: event.departureDate,
    returnDate: event.returnDate,
    totalAdults: event.totalAdults,
    totalChildren: event.totalChildren,
    shareMethod: event.shareMethod,
  });
};
