import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TCurrency } from '@/config/locales';
import { formatCurrency } from '@/utility/currency';

type TMobileTotalPriceProps = {
  total: number;
  currency?: TCurrency;
};

export const MobileTotalPrice: React.FC<TMobileTotalPriceProps> = ({ total, currency }) => {
  return (
    <Text
      component="span"
      variant={ETextStyleVariant.SmallRegular}
      className="text-gray-400 underline">
      <FormattedMessage
        defaultMessage="{total} Est. total"
        id="BKLfj/"
        values={{
          total: formatCurrency({
            priceInCents: total,
            currency: currency || 'USD',
            digits: 2,
          }),
        }}
      />
    </Text>
  );
};
